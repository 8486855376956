import { toast } from "react-toastify";
import axios from 'axios';
import ScanResults from "./ScanResults";
import "../../../assets/css/common.css";
import "../../../assets/css/systemcontrolcenter.css";
import "../../../assets/css/rundata.css";
import { useNavigate } from "react-router-dom";
import React, {useState, useEffect, useCallback, useRef, lazy} from "react";
import { CButton, CCol, CContainer, CRow, CFormSwitch} from "@coreui/react";
import jwtInterceoptor from "../../../views/shared/jwtInterceptor";
import { cookieUtils } from "../../../utils/cookieUtils";
import { AxiosError } from "axios";
import { FaShieldAlt } from "react-icons/fa";
import ErrorModal from './Modals/ErrorModal';
import ScheduleAutomationModal from "./Modals/ScheduleAutomationModal";

declare global {
	interface Navigator {
		wakeLock: WakeLock;
	}

	interface WakeLock {
		request(type: "screen"): Promise<WakeLockSentinel>;
	}

	interface WakeLockSentinel {
		release(): Promise<void>;
	}
}

type StateObjectTypes = {
	dated: string;
	status: boolean;
	message?: string;
};

type FailedComponent = 'Calibration' | 'Diagnostic' | 'Solvent Reference';

// Lazy load components
const SelectYourHardware = React.memo(lazy(() => import('./Cards/SelectYourHardware')));
const Diagnostic = React.memo(lazy(() => import('./Cards/Diagnostic')));
const Calibration = React.memo(lazy(() => import('./Cards/Calibration')));
const SolventReference = React.memo(lazy(() => import('./Cards/SolventReference')));

const SystemControlCenter = () => {

  const navigate = useNavigate();
  const [automationStatus, setAutomationStatus] = useState<boolean>(false);
  const [isCanDisable, setIsCanDisable] = useState<boolean>(true);
  const [nextScheduledTime, setNextScheduledTime] = useState<string | null>(null);
  const [lastCompletedWorkflow, setLastCompletedWorkflow] = useState<string | null>(null);
  const [latestDiagnostic, setLatestDiagnostic] = useState<StateObjectTypes>({ status: false, dated: "--/--/--", message: "" })
  const [latestUnitCalibration, setLatestUnitCalibration] = useState<StateObjectTypes>({ status: false, dated: "--/--/--", message: "" })
  const [latestSolventReference, setLatestSolventReference] = useState<StateObjectTypes>({ status: false, dated: "--/--/--", message: "" })
  const [showModal, setShowModal] = useState(false);
	const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);
	const [connections, setConnection] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState(() => {
    const storedConnection = JSON.parse(cookieUtils.get("selectedHardware")) || {};
    return storedConnection || null;
  });
  const [deviceStatus, setDeviceStatus] = useState(false);
  const [diagnosticSuccess, setDiagnosticSuccess] = useState(
    () => cookieUtils.get("systemHealth") === "true" || false
  );
  const [calibrationSuccess, setCalibrationSuccess] = useState(
    () => cookieUtils.get("systemHealth") === "true" || false
  );
  const [solventSuccess, setSolventSuccess] = useState(
    () => cookieUtils.get("systemHealth") === "true" || false
  );

  // Update errorModal state type
  const [errorModal, setErrorModal] = useState({
    visible: false,
    title: '',
    message: '',
    failedComponent: '' as FailedComponent,
    retryCount: 0
  });

  // Update retryCountRef to match FailedComponent type
  const retryCountRef = useRef({
    'Calibration': 0,
    'Diagnostic': 0,
    'Solvent Reference': 0
  });

  // Add these state variables at the top of the component
  const [showDiagnosticModal, setShowDiagnosticModal] = useState(false);
  const [showSolventModal, setShowSolventModal] = useState(false);

  const notifyAutomationServer = async (machineData: {
    machine_name: string | undefined;
    device_type: string;
    command: string;
  }) => {
    try 
    {
      const userEmail = cookieUtils.get("UserEmail") || "unknown";

      // Get current date in EST
      const estDate = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
        month: 'numeric',
        day: 'numeric',
        year: 'numeric'
      });

      // Get current time in EST
      const estTime = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
      });

      const payload = {
        ...machineData,
        user: userEmail,
        date: estDate,
        time: estTime,
        branch: 'development',
      };

      await axios.post(
        `${process.env.REACT_APP_POWER_AUTOMATION_API_URL}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
    } catch (error) {
      console.error('Failed to notify automation server:', error);
    }
  };

  const fetchSCC = useCallback(async () => {
    try {
      const response = await jwtInterceoptor.get(
        `${process.env.REACT_APP_API_URL}/user/api/automation-schedules/get_active_device/`
      );

      // Update device status
      if (response.data.device) {
        setIsCanDisable(response.data.device.can_disable);
        setDeviceStatus(response.data.device.status);
        const isAutomationActive = response.data.device.is_automation_on;
        setAutomationStatus(isAutomationActive);

        if (response.data.device.serial_number && response.data.device.name) {
          cookieUtils.set(
            "SelectedHardware",
            JSON.stringify({
              id: response.data.device.id,
              serial_number: response.data.device.serial_number,
              machine_name: response.data.device.name,
              dated: new Date(response.data.device.last_updated)
            })
          );
          setSelectedConnection({
            id: response.data.device.id,
            serial_number: response.data.device.serial_number,
            machine_name: response.data.device.name,
            dated: new Date(response.data.device.last_updated)
          });
        }
      }

      // Update automation states
      if (response.data.automation) {
        // Update scheduled time and last completed workflow
        if (response.data.automation.next_run) {
          setNextScheduledTime(formatDateTime(response.data.automation.next_run));
        }
        if (response.data.automation.last_run) {
          setLastCompletedWorkflow(formatDateTime(response.data.automation.last_run));
        }

        // Update task results
        const taskResults = response.data.automation.task_results;
        if (taskResults) {
          // Update Diagnostic status
          if (taskResults.diagnostic) {
            setDiagnosticSuccess(taskResults.diagnostic.status === "success");
            if (taskResults.diagnostic.timestamp) {
              setLatestDiagnostic({
                dated: new Date(taskResults.diagnostic.timestamp).toISOString(),
                status: taskResults.diagnostic.status === "success",
                message: taskResults.diagnostic.message || ""
              });
            }
          }

          // Update Calibration status
          if (taskResults.calibration) {
            setCalibrationSuccess(taskResults.calibration.status === "success");
            if (taskResults.calibration.timestamp) {
              setLatestUnitCalibration({
                dated: new Date(taskResults.calibration.timestamp).toISOString(),
                status: taskResults.calibration.status === "success",
                message: taskResults.calibration.message || ""
              });
            }
          }

          // Update Solvent Reference status
          if (taskResults.solvent_reference) {
            setSolventSuccess(taskResults.solvent_reference.status === "success");
            if (taskResults.solvent_reference.timestamp) {
              setLatestSolventReference({
                dated: new Date(taskResults.solvent_reference.timestamp).toISOString(),
                status: taskResults.solvent_reference.status === "success",
                message: taskResults.solvent_reference.message || ""
              });
            }
          }
        }
      } else {
        setLatestDiagnostic({ status: false, dated: "--/--/--", message: "" });
        setLatestUnitCalibration({ status: false, dated: "--/--/--", message: "" });
        setLatestSolventReference({ status: false, dated: "--/--/--", message: "" });
      }

    } catch (error) {
      console.error("Error fetching automation status:", error);
      toast.error("Failed to fetch system status");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to handle hardware scanning
  const handleScanHardware = async () => {
    setShowSolventModal(true);
  };

  const handleScanHardwareCopy = useCallback(async () => {
    let toastId = null;
    try {
      toastId = toast.loading("Taking a New Solvent Reference...");

      // Starting notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Solvent Reference'] > 0
          ? `Reference started (Attempt ${retryCountRef.current['Solvent Reference'] + 1})`
          : 'Reference started'
      });

      const response = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=scan_hardware&scan_type=solvent`
      );

      // Success notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Solvent Reference'] > 0
          ? `Completed Successful Reference (Attempt ${retryCountRef.current['Solvent Reference'] + 1})`
          : 'Completed Successful Reference'
      });

      // Reset retry count on success
      retryCountRef.current['Solvent Reference'] = 0;

      let latestSolventReference = JSON.stringify({
        ...response.data,
        dated: new Date(),
      });
      cookieUtils.set("latestSolventReference", latestSolventReference);
      setLatestSolventReference({ status: true, dated: new Date() + "", message: "" });
      setSolventSuccess(true);
      cookieUtils.set("solvent_scan_id", response.data.scan_id);

      toast.update(toastId, {
        render: "Successfully took a new Solvent Reference",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      fetchSCC();
    } catch (error) {
      retryCountRef.current['Solvent Reference']++;

      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Solvent Reference'] > 1
          ? `Failed Reference (Attempt ${retryCountRef.current['Solvent Reference']})`
          : 'Failed Reference'
      });

      cookieUtils.remove("latestSolventReference");
      setLatestSolventReference({ status: false, dated: new Date() + "", message: "" });
      setSolventSuccess(false);
      setErrorModal({
        visible: true,
        title: 'Solvent Reference Failed',
        message: retryCountRef.current['Solvent Reference'] >= 2
          ? 'Multiple Solvent Reference attempts failed. Please contact support to resolve the issue.'
          : 'Solvent Reference failed. Please ensure the system is properly connected and try again.',
        failedComponent: 'Solvent Reference',
        retryCount: retryCountRef.current['Solvent Reference']
      });
      if (toastId) {
        toast.update(toastId, {
          render: (
            <div>
                <p style={{fontSize: '14px'}}>Error taking a new Solvent Reference</p>
                <p style={{fontSize: '12px', marginTop: '-10px'}}>
                {error instanceof AxiosError ? error.response?.data?.message : 'An unknown error occurred'}
              </p>
            </div>
          ),
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    }
  }, [selectedConnection, fetchSCC]);

  const fetchData = async () => {
    setShowDiagnosticModal(true);
  };

  const fetchDataCopy = useCallback(async () => {
    let toastId = toast.loading("Running System Diagnostic...");
    try {
      // Starting notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Diagnostic'] > 0
          ? `Diagnostic started (Attempt ${retryCountRef.current['Diagnostic'] + 1})`
          : 'Diagnostic started'
      });

      const lampResponse = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=diagnostic`,
        "diagnostic_type=lamp",
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      if (lampResponse.status === 201 || lampResponse.status === 200) {
        setDiagnosticSuccess(true);
        const failedTests = Object.keys(lampResponse.data).filter(
          (lampKey) => lampResponse.data[lampKey].test_result === "Fail"
        );

        if (failedTests.length > 0) {
          // Failure notification with retry count
          await notifyAutomationServer({
            machine_name: selectedConnection?.machine_name,
            device_type: 'System Control Center',
            command: retryCountRef.current['Diagnostic'] > 1
              ? `Failed Diagnostic (Attempt ${retryCountRef.current['Diagnostic']})`
              : 'Failed Diagnostic'
          });
          toast.update(toastId, {
            render: "Please Clean the Flow Cell",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          // Success notification with retry count
          await notifyAutomationServer({
            machine_name: selectedConnection?.machine_name,
            device_type: 'System Control Center',
            command: retryCountRef.current['Diagnostic'] > 0
              ? `Completed Successful Diagnostic (Attempt ${retryCountRef.current['Diagnostic'] + 1})`
              : 'Completed Successful Diagnostic'
          });

          // Reset retry count on success
          retryCountRef.current['Diagnostic'] = 0;

          let latestDiagnostic = JSON.stringify({ dated: new Date() });
          cookieUtils.set("latestDiagnostic", latestDiagnostic);
          setLatestDiagnostic({ status: true, dated: new Date() + "", message: "" });
          toast.update(toastId, {
            render: "Diagnostics Ran Successfully",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });
        }
      }
      fetchSCC();
    } catch (error) {
      retryCountRef.current['Diagnostic']++;
      setDiagnosticSuccess(false);
      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Diagnostic'] > 1
          ? `Failed Diagnostic (Attempt ${retryCountRef.current['Diagnostic']})`
          : 'Failed Diagnostic'
      });

      console.error("Error fetching data:", error);
      setLatestDiagnostic({ status: false, dated: new Date() + "", message: "" });
      setErrorModal({
        visible: true,
        title: 'Diagnostics Failed',
        message: retryCountRef.current['Diagnostic'] >= 2
          ? 'Please contact support to resolve the issue.'
          : `${(error instanceof AxiosError) ? error.response?.data?.message : 'An unknown error occurred'}. Please contact support for help`,
        failedComponent: 'Diagnostic',
        retryCount: retryCountRef.current['Diagnostic']
      });
      toast.update(toastId, {
        render: (
          <div>
            <p style={{fontSize: '14px'}}>Error Running the Diagnostics</p>
            <p style={{fontSize: '12px', marginTop: '-10px'}}>
              {error instanceof AxiosError ? error.response?.data?.message : 'An unknown error occurred'}
            </p>
          </div>
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  }, [selectedConnection, fetchSCC]);

	const handleYesClick = () => {
		fetchData();
	};

	const fetchDevices = async () => {
		const user_connection = await jwtInterceoptor.get(
			`${process.env.REACT_APP_API_URL}/user/api/user-connection/`
		);
		return user_connection?.data?.results;
  };

  const formatDateTime = (dateString: string): string => {
    try {
      const date = new Date(dateString);

      // Check if date is valid
      if (isNaN(date.getTime())) {
        return '--/--/-- --:--';
      }

      // Format in local timezone with AM/PM
      const formattedDate = date.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }).replace(',', '');

      // Log for debugging
      console.log({
        original: dateString,
        formatted: formattedDate,
        localString: date.toLocaleString()
      });

      return formattedDate;

    } catch (error) {
      console.error('Error formatting date:', error);
      return '--/--/-- --:--';
    }
  };

	useEffect(() => {
		fetchDevices().then((data) => {
			if (data) {
				const currentUrl = window.location.href;
				if (currentUrl.includes('demo')) {
					const filteredData = data.filter(
						(device: { machine_name: string; }) => device.machine_name.toLowerCase() === 'dummy'
					);
					setConnection(filteredData);
				}else{
					setConnection(data);
				}
			}
		});
		//cleanup
		return () => {
			setConnection([]);
		};
  }, []);

  const stopAutomation = async () => {
    const toastId = toast.loading("Stopping Automation...");
    try {
      await jwtInterceoptor.post(`${process.env.REACT_APP_API_URL}/user/api/automation-schedules/stop_automation/`, {
        serial_number: selectedConnection?.serial_number,
      });
      fetchSCC();
      toast.update(toastId, {
        render: "Automation Stopped",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error stopping automation:", error);
      toast.update(toastId, {
        render: "Error Stopping Automation",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  }

  useEffect(() => {
    fetchSCC()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

	const checkDeviceStatus = useCallback(async (selectedConnection: any) => {

		let deviceStatus;
		let toastId;
		toastId = toast.loading("Checking Hardware Status");
		try {
			const response = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/set-active-device/`,
          {
            serial_number: selectedConnection?.serial_number,
          }
			);
      if(response.data.device){
        deviceStatus = response.data.device.status;
        setDeviceStatus(deviceStatus);
      }

      setNextScheduledTime(null);
      setLastCompletedWorkflow(null);

      await fetchSCC();

			toast.update(toastId, {
				render: `${response.data.device.name} Connected`,
				type: toast.TYPE.SUCCESS,
        isLoading: false,
        autoClose: 3000,
      });

		} catch (error) {
			toast.update(toastId, {
				render: "Failed to check hardware status",
				type: toast.TYPE.ERROR,
				isLoading: false,
				autoClose: 3000,
			});
		}
	}, [setDeviceStatus, fetchSCC]);

	const handleConnections = useCallback(() => {
		if (!selectedConnection) {
			toast.error("Select your Hardware");
			return;
		}
		checkDeviceStatus(selectedConnection);
	}, [selectedConnection, checkDeviceStatus]);

  useEffect(() => {
    if (selectedConnection?.id) {
      handleConnections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnitCalibration = useCallback(async () => {
    var toastId = toast.loading("Calibrating...");
    try {
      // Starting notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Calibration'] > 0
          ? `Calibration started (Attempt ${retryCountRef.current['Calibration'] + 1})`
          : 'Calibration started'
      });

      const calibration = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=calibrate`
      );

      if (calibration?.status === 201 || calibration?.status === 200) {
        setCalibrationSuccess(true);
        // Success notification with retry count
        await notifyAutomationServer({
          machine_name: selectedConnection?.machine_name,
          device_type: 'System Control Center',
          command: retryCountRef.current['Calibration'] > 0
            ? `Completed Successful Calibration (Attempt ${retryCountRef.current['Calibration'] + 1})`
            : 'Completed Successful Calibration'
        });

        // Reset retry count on success
        retryCountRef.current['Calibration'] = 0;

        toast.update(toastId, {
          render: "Successfully Calibrated",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        cookieUtils.set(
          "latestUnitCalibration",
          JSON.stringify({ dated: new Date() })
        );
        setLatestUnitCalibration({
          dated: new Date() + "",
          status: true,
          message: ""
        });
      }
      fetchSCC();
    } catch (error) {
      retryCountRef.current['Calibration']++;

      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Calibration'] > 1
          ? `Failed Calibration (Attempt ${retryCountRef.current['Calibration']})`
          : 'Failed Calibration'
      });

      cookieUtils.remove("latestUnitCalibration");
      setLatestUnitCalibration({ status: false, dated: new Date() + "", message: "" });
      setCalibrationSuccess(false);
      setErrorModal({
        visible: true,
        title: 'Calibration Failed',
        message: retryCountRef.current['Calibration'] >= 2
          ? 'Multiple calibration attempts failed. Please contact support to resolve the issue.'
          : 'Calibration failed. Please ensure the system is properly connected and try again.',
        failedComponent: 'Calibration',
        retryCount: retryCountRef.current['Calibration']
      });
      toast.update(toastId, {
        render: (
          <div>
            <p style={{fontSize: '14px'}}>Error Calibrating Spectrometer</p>
            <p style={{fontSize: '12px', marginTop: '-10px'}}>
              {error instanceof AxiosError ? error.response?.data?.message : 'An unknown error occurred'}
            </p>
          </div>
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  }, [selectedConnection, fetchSCC]);

  const notifyStatus = async (isStarting: boolean) => {
    try {
      const userEmail = cookieUtils.get("UserEmail") || "unknown";

      // Get current date in EST
      const estDate = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
        month: 'numeric',
        day: 'numeric',
        year: 'numeric'
      });

      // Get current time in EST
      const estTime = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
      });

      const payload = {
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: isStarting ? 'Automation Started' : 'Automation Stopped',
        date: estDate,
        time: estTime,
        user: userEmail,
        branch: 'development',
      };

      const response = await axios.post(
        `${process.env.REACT_APP_POWER_AUTOMATION_API_URL}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log(response, "response");
    } catch (error) {
      console.log(error, "error");
    }
  }

  const openScheduleAutomationModal = async (e: any) => {
    if (e?.target?.checked) {
      setShowModal(true);
    } else {
      // toast.info("Automation disabled.");
      stopAutomation();
      notifyStatus(false);
      setAutomationStatus(false);
      setNextScheduledTime(null);
      setLastCompletedWorkflow(null);
    }
  };

  const addInterval = async (time: string, interval: string) => {
    try {
      // Convert local time to UTC
      const [hours, minutes] = time.split(':').map(Number);
      const now = new Date();
      const localDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);

      // Convert to UTC time string in HH:mm format
      const utcTime = new Date(localDate).toLocaleTimeString('en-US', {
        timeZone: 'UTC',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
      });

      const payload = {
        serial_number: selectedConnection?.serial_number,
        time: utcTime, // Send UTC time
        interval: interval,
        is_active: true,
      };

      await jwtInterceoptor.post(`${process.env.REACT_APP_API_URL}/user/api/automation-schedules/`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("Interval added successfully:", payload);
      await fetchSCC();
    } catch (error) {
      console.error("Error adding interval:", error);
    }
  }

  const handleSave = (time: string, interval: string) => {
    notifyStatus(true);
    setAutomationStatus(true);
    addInterval(time, interval);
    setShowModal(false);
  };

	// First, define requestWakeLock and releaseWakeLock with useCallback
	const requestWakeLock = useCallback(async () => {
		try {
			const wakeLock = await navigator.wakeLock.request('screen');
			setWakeLock(wakeLock);
			console.log('Wake Lock is active');
		} catch (err: any) {
			console.error(`${err.name}, ${err.message}`);
		}
	}, []);

	const releaseWakeLock = useCallback(async () => {
		if (wakeLock) {
			await wakeLock.release();
			setWakeLock(null);
			console.log('Wake Lock has been released');
		}
	}, [wakeLock]);  // Only depends on wakeLock state

	const handleVisibilityChange = useCallback(async () => {
		if (document.visibilityState === 'visible') {
			await requestWakeLock();
		} else {
			await releaseWakeLock();
		}
	}, [requestWakeLock, releaseWakeLock]);

	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [handleVisibilityChange]);

  useEffect(() => {
    console.log(diagnosticSuccess, calibrationSuccess, solventSuccess, "diagnosticSuccess, calibrationSuccess, solventSuccess");
    cookieUtils.set(
      "systemHealth",
      diagnosticSuccess && calibrationSuccess && solventSuccess
        ? true
        : false
    );
  }, [diagnosticSuccess, calibrationSuccess, solventSuccess]);

  const isAllSystemsHealthy =
    latestUnitCalibration?.status &&
    latestDiagnostic?.status &&
    latestSolventReference?.status;

  const handleRetry = () => {
    if (errorModal.failedComponent === 'Calibration') handleUnitCalibration();
    if (errorModal.failedComponent === 'Diagnostic') fetchDataCopy();
    if (errorModal.failedComponent === 'Solvent Reference') handleScanHardwareCopy();
  };

  return (
    <>
      <div className="flow-strip-container">
        <div className={`flow-strip ${isAllSystemsHealthy ? '' : 'unhealthy'}`} />
      </div>
      <CContainer className="mt-4">
        <div className="system-overview-container">
          <h1 className="system-overview-title">
            System Health Overview
          </h1>

          <div className="mb-2">
            <div className="d-flex justify-content-center align-items-center automation-switch-label">
              <CFormSwitch
                checked={automationStatus}
                label="Manual / Automated"
                onChange={openScheduleAutomationModal}
                disabled={!isCanDisable}
                className="d-flex justify-content-center align-items-center gap-2 automation-switch"
              />
            </div>
          </div>

          <div className={`system-health-status ${!isAllSystemsHealthy ? 'health-blip' : ''}`}>
            <div className="health-icon-container">
              <FaShieldAlt
                size={22}
                style={{ marginTop: '-10px' }}
                color={!isAllSystemsHealthy ? "#dc3545" : "#4CAF50"}
              />
              <h2 className="health-status-text" style={{ color: !isAllSystemsHealthy ? "#dc3545" : "#4CAF50" }}>
                System Health Status:
                {!isAllSystemsHealthy
                  ? " One or more systems require attention"
                  : " All systems are operational"}
              </h2>
            </div>
          </div>

          <ScheduleAutomationModal
            show={showModal}
            onClose={() => setShowModal(false)}
            onSave={handleSave}
          />
        </div>

        <div className="d-flex justify-content-center align-items-center gap-3 p-2 schedule-info">
          <div>
            Next Scheduled Time:
            <span className="schedule-info-value">
              {nextScheduledTime || "Not scheduled"}
            </span>
          </div>
          <div>
            Last Completed Workflow:
            <span className="schedule-info-value">
              {lastCompletedWorkflow || "Never"}
            </span>
          </div>
        </div>

        <CContainer>
          <div className="system-container">
            <CCol className="card-column">
              <SelectYourHardware
                selectedConnection={selectedConnection}
                setSelectedConnection={setSelectedConnection}
                connections={connections}
                deviceStatus={deviceStatus}
                handleConnections={handleConnections}
              />
            </CCol>
            <CCol className="card-column">
              <Diagnostic
                latestDiagnostic={latestDiagnostic}
                handleYesClick={handleYesClick}
              />
            </CCol>
            <CCol className="card-column">
              <Calibration
                latestUnitCalibration={latestUnitCalibration}
                handleUnitCalibration={handleUnitCalibration}
              />
            </CCol>
            <CCol className="card-column">
              <SolventReference
                latestSolventReference={latestSolventReference}
                handleScanHardware={handleScanHardware}
              />
            </CCol>
          </div>
        </CContainer>

        <CRow>
          <div className="d-flex align-items-center justify-content-center measure-button-container">
            <CButton
              variant="ghost"
              type="submit"
              className={`measure-button ${isAllSystemsHealthy ? "active" : ""}`}
              onClick={() => navigate("/ExtractoPredictionDashboard")}
            >
              Start Measuring Sample
            </CButton>
          </div>
        </CRow>
        <ErrorModal
          visible={errorModal.visible}
          title={errorModal.title}
          message={errorModal.message}
          failedComponent={errorModal.failedComponent}
          retryCount={retryCountRef.current[errorModal.failedComponent]}
          onClose={() => setErrorModal(prev => ({ ...prev, visible: false }))}
          onRetry={handleRetry}
        />
      </CContainer>

      <ScanResults
        show={showDiagnosticModal}
        onClose={() => setShowDiagnosticModal(false)}
        title="Scan"
        question="Is the pipetrain CLEAR of solvent and sample?"
        yesOption="Yes, run a new Diagnostic Scan."
        noOption={null}
        centerYesButton={true}
        onYes={fetchDataCopy}
      />

      <ScanResults
        show={showSolventModal}
        onClose={() => setShowSolventModal(false)}
        title="Scan"
        question="Is the pipetrain empty?"
        yesOption="Yes, take a new Reference"
        noOption={null}
        centerYesButton={true}
        onYes={handleScanHardwareCopy}
      />
    </>
	);
};

export default SystemControlCenter;
